var $class="se2--content-overlapping-content-blocks",$name="ContentOverlappingContentBlocks",$path="app/components/ContentOverlappingContentBlocks/index.js",$this={$class,$name,$path,};import handleSlidesNavigation from 'app/components/utilities/handleSlidesNavigation';
import shell from 'app/modules/shell';

import 'app/partials/image';

import capArrayIndex from 'app/utilities/capArrayIndex';
import findPreviousSibling from 'app/utilities/findPreviousSibling';
import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import setTabIndex from 'app/utilities/setTabIndex';

import { css, delays, directions, keyNames, mouseButtons, zIndexes } from 'configs';

import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import debounce from 'lodash/debounce';
import forEach from 'lodash/forEach';
import last from 'lodash/last';
import map from 'lodash/map';
import throttle from 'lodash/throttle';
import slice from 'lodash/slice';

import {
  aliasClassName,
  offsetTopDesktop,
  offsetTopMobile,
  sliderContainerClassName,
  slideClassName,
  wrapperClassName,
} from './config';

const cardWidthDesktop = 230;
const cardWidthMobile = 170;
const defaultSlideIndex = 0;
const slideDuration = 0.3;
const inactiveClassName = 'inactive';
const minimumMovementValue = 90;
const nextButtonSelector = '.slider-button-next';
const prevButtonSelector = '.slider-button-prev';
const paginationBulletClassName = 'pagination-bullet';
const paginationSelector = '.pagination';
const smallClassName = 'small';

export default shell.registerComponent($this, ({
  addEventListener, mount, settings,
}) => {
  const { classNames } = css;

  const initSlider = (element) => {
    const phantomElement = document.createElement('div');
    const slides = element.querySelectorAll(`.${slideClassName}`);
    const cardsContainer = element.querySelector(`.${sliderContainerClassName}`);
    const nextButton = element.querySelector(nextButtonSelector);
    const prevButton = element.querySelector(prevButtonSelector);
    const paginationContainerElement = element.querySelector(paginationSelector);
    const allBullets = [];
    let shortendBullets = [];
    let windowInnerWidth = window.innerWidth;

    let dragStart = 0;
    let activeSlide = 0;
    let positions = map(slides, (slide) => (
      settings.direction === directions.rtl
        ? slide.getBoundingClientRect().right - cardsContainer.getBoundingClientRect().right
        : slide.offsetLeft - cardsContainer.offsetLeft
    ));

    const addOffsetToSlider = () => {
      const { style } = element.querySelector(`.${wrapperClassName}`);
      const offsetTop = isMobileBreakpoint() ? offsetTopMobile : offsetTopDesktop;

      // TODO: decide what to do with logical styles in JS
      style.insetBlockStart = '';

      if (findPreviousSibling(
        element,
        (candidate) => candidate?.classList.contains(aliasClassName),
      )) {
        element.style.height = 'auto';
        style.position = 'relative';
        style.zIndex = zIndexes.above;
        style.insetBlockStart = `${-offsetTop}px`;
        element.style.height = `${element.getBoundingClientRect().height - offsetTop}px`;
      }
    };

    const updatePaginationActiveArea = (firstBullet, lastBullet) => {
      const firstBulletIndex = +firstBullet.dataset.index;
      const lastBulletIndex = +lastBullet.dataset.index;

      if (
        lastBullet.className.includes(classNames.active)
        && +shortendBullets[shortendBullets.length - 2]?.dataset.index !== allBullets.length - 2
      ) {
        paginationContainerElement.textContent = '';
        shortendBullets.length = 0;

        shortendBullets = slice(allBullets, lastBulletIndex - 2, lastBulletIndex + 2);
        forEach(shortendBullets, (bullet) => paginationContainerElement.append(bullet));
      }

      if (
        firstBullet.className.includes(classNames.active)
        && !(+shortendBullets[1]?.dataset.index === 1)
      ) {
        paginationContainerElement.textContent = '';
        shortendBullets.length = 0;

        shortendBullets = slice(allBullets, firstBulletIndex - 1, firstBulletIndex + 3);
        forEach(shortendBullets, (bullet) => paginationContainerElement.append(bullet));
      }
    };

    const toggleInactiveArrowButtons = () => {
      nextButton.classList.toggle(
        inactiveClassName,
        last(shortendBullets).className.includes(classNames.active)
        && (+last(shortendBullets).dataset.index === allBullets.length - 1),
      );

      prevButton.classList.toggle(
        inactiveClassName,
        shortendBullets[0].className.includes(classNames.active)
        && (+shortendBullets[0].dataset.index === 0),
      );
    };

    const toggleSmallPaginationBullets = () => {
      shortendBullets[0].classList.toggle(
        smallClassName,
        +shortendBullets[0]?.dataset.index !== 0,
      );

      last(shortendBullets).classList.toggle(
        smallClassName,
        +last(shortendBullets).dataset.index !== allBullets.length - 1,
      );
    };

    const updatePagination = () => {
      const firstBullet = shortendBullets[0];
      const lastBullet = last(shortendBullets);

      updatePaginationActiveArea(firstBullet, lastBullet);
      toggleInactiveArrowButtons();
      toggleSmallPaginationBullets();
    };

    const selectSlide = (index) => {
      activeSlide = capArrayIndex(index, slides);

      gsap.to(slides, {
        x: -positions[activeSlide],
        duration: slideDuration,
      });

      forEach(
        allBullets,
        (bullet, bulletNumber) => {
          const isActive = activeSlide === bulletNumber;
          if (isActive) bullet.classList.remove(smallClassName);
          bullet.classList.toggle(classNames.active, isActive);
        },
      );

      updatePagination();
    };

    const handleArrowsDisplay = () => {
      const isInsufficientBulletsLength = shortendBullets.length <= 1;
      const isHiddenButton = isMobileBreakpoint() || isInsufficientBulletsLength;

      nextButton.classList.toggle(classNames.hide, isHiddenButton);
      prevButton.classList.toggle(classNames.hide, isHiddenButton);
      paginationContainerElement.classList.toggle(classNames.hide, isInsufficientBulletsLength);
    };

    const onPrevButtonClick = () => {
      if (activeSlide !== 0) {
        selectSlide(activeSlide - 1);
      }
    };

    const onNextButtonClick = () => {
      if (cardsContainer.scrollWidth - cardsContainer.clientWidth > 0 && activeSlide + 1 !== slides.length) {
        selectSlide(activeSlide + 1);
      }
    };

    const throttleOnPrevButtonClick = throttle(onPrevButtonClick, slideDuration * delays.s1);
    const throttleOnNextButtonClick = throttle(onNextButtonClick, slideDuration * delays.s1);
    addEventListener(prevButton, 'click', throttleOnPrevButtonClick);
    addEventListener(nextButton, 'click', throttleOnNextButtonClick);

    const onDragStart = ({ clientX }) => {
      dragStart = clientX;
    };

    const onDragEnd = throttle(({ clientX }) => {
      const offset = settings.direction === directions.rtl
        ? clientX - dragStart
        : dragStart - clientX;

      if (offset < 0 && activeSlide !== 0) {
        selectSlide(activeSlide - 1);
      } else if (
        offset > 0 && cardsContainer.scrollWidth - cardsContainer.clientWidth > 0 && activeSlide !== slides.length - 1
      ) {
        selectSlide(activeSlide + 1);
      }
    }, slideDuration * delays.s1);

    const onClick = (event) => {
      if (event.button === mouseButtons.middle) {
        event.preventDefault();
      }
    };

    const generatePagination = () => {
      paginationContainerElement.textContent = '';
      allBullets.length = 0;
      shortendBullets.length = 0;

      const cardWidth = isMobileBreakpoint() ? cardWidthMobile : cardWidthDesktop;
      let cardsContainerScrollWidth = cardsContainer.scrollWidth - cardsContainer.clientWidth;
      let index = 0;

      while (cardsContainerScrollWidth > -cardWidth) {
        const paginationBulletElement = document.createElement('div');
        paginationBulletElement.classList.add(paginationBulletClassName);
        paginationBulletElement.setAttribute('data-index', index);
        setTabIndex(paginationBulletElement, false);

        if (index === 0) paginationBulletElement.classList.add(classNames.active);

        allBullets.push(paginationBulletElement);
        cardsContainerScrollWidth -= cardWidth;
        index += 1;
      }

      shortendBullets = slice(allBullets, 0, 4);

      if (allBullets.length - 1 > 3) {
        shortendBullets[3].classList.add(smallClassName);
      }

      forEach(shortendBullets, (bullet) => paginationContainerElement.append(bullet));
      forEach(allBullets, (bullet, bulletNumber) => addEventListener(bullet, 'click', () => {
        selectSlide(bulletNumber);
      }));

      handleArrowsDisplay();
    };

    handleArrowsDisplay();
    generatePagination();
    addOffsetToSlider();
    selectSlide(defaultSlideIndex);
    addEventListener(cardsContainer, 'keydown', (event) => {
      handleSlidesNavigation(event, prevButton, nextButton);
      if (event.code === keyNames.enter && event.target.classList.contains(slideClassName)) {
        event.target.querySelector('a').click();
      }
    });

    // eslint-disable-next-line consistent-return
    return {
      draggable: new Draggable(
        phantomElement,
        {
          activeCursor: 'grabbing',
          allowContextMenu: true,
          cursor: 'default',
          dragClickables: true,
          minimumMovement: minimumMovementValue,
          throwProps: true,
          trigger: cardsContainer,
          type: 'x',
          onDragStart,
          onDragEnd,
          onClick,
        },
      ),
      onResize: () => {
        if (windowInnerWidth !== window.innerWidth) {
          windowInnerWidth = window.innerWidth;
          activeSlide = defaultSlideIndex;
          const cardsContainerOffsetRight = cardsContainer.offsetLeft + cardsContainer.offsetWidth;

          positions = map(slides, (slide) => {
            handleArrowsDisplay();
            const slideOffsetRight = slide.offsetLeft + slide.offsetWidth;

            return settings.direction === directions.rtl
              ? slideOffsetRight - cardsContainerOffsetRight
              : slide.offsetLeft - cardsContainer.offsetLeft;
          });

          gsap.set(slides, {
            x: -positions[activeSlide],
          });

          addOffsetToSlider();
          generatePagination();
        }
      },
    };
  };

  mount((element) => {
    const slider = initSlider(element);
    if (slider) addEventListener(window, 'resize', debounce(slider.onResize, delays.ms300));
  });
});
